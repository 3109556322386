const styles = {
  filter: {
    flexBasis: '240px',
    flexShrink: 0,
    marginRight: {
      md: 8,
    },
    display: 'flex',
    flexDirection: 'column'
  },
}

export default styles;